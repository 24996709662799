@font-face {
  font-family: "Azonix";
  src: url("../../assets/Azonix.otf");
}

#video-wrapper {
  display: none;
}

#bg-video {
  height: min-content;
  width: 100%;
  position: relative;
}

#hero-container {
  display: flex;
  align-items: center;
  height: 100vh;
  min-height: 100vh;
}

#hero-content {
  position: absolute;
  left: 0;
  padding-left: 10%;
  z-index: 2;
}

.cxl-logo h1 {
  width: fit-content;
  background: linear-gradient(to right, #f12711, #f5af19);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  text-align: left;
  font-size: 4rem;
  font-family: "Azonix";
  font-weight: 100;
}

.hero-span {
  font-size: 2rem;
}

.hero-text h2 {
  font-size: 2.5rem;
}

.anchor {
  position: relative;
  top: -100px;
  visibility: hidden;
}
#exp-more {
  width: fit-content;
  font-size: large;
  border-radius: 20px;
}

@media screen and (min-width: 990px) {
  .hero-title {
    font-size: 60px;
  }
  .hero-subtitle {
    font-size: 25px;
    padding-left: 15rem;
    padding-right: 15rem;
    line-height: 35px;
  }

  #hero-container {
    height: auto;
  }

  #video-wrapper {
    display: block;
  }
}

@media screen and (min-width: 1200px) {
  .nav-item:first-child {
    line-height: 2;
  }
}
