.product-info {
  background: #304052;
  border-radius: 20px;
}

.product-row:hover .cxai-product-img {
  transform: scale(1.5);
}

.cxai-img {
  width: 10rem;
  align-self: center;
}

#cxneuro {
  height: 50.2px;
  width: auto;
}

.cxai-text,
.mis-vis {
  text-align: justify;
  text-justify: inter-word;
  line-height: 35px;
}

button {
  background: #283e51;
  border: none;
  border-radius: 30px;
}

#btn2 {
  margin-left: 1.5rem;
}

.cxai-btn {
  border-radius: 20px;
  filter: drop-shadow(0px 0px 12.1603px rgba(158, 158, 158, 0.14));
  background: linear-gradient(
    0deg,
    rgba(40, 62, 81, 0.81),
    rgba(40, 62, 81, 0.81)
  );
}

.cxai-product-img {
  transition: 200ms ease;
}

.row2 {
  display: flex;
  flex-direction: row-reverse;
}

.mis-vis {
  background: #152230;
  border-radius: 20px;
}

.about-us {
  background: #123f70;
  border-radius: 20px;
}

.about-us-text {
  text-align: justify;
  text-justify: inter-word;
  line-height: 30px;
}

.text-underline {
  width: fit-content;
  margin: auto;
  border-bottom: 1px solid white;
}

@media screen and (max-width: 1200px) {
  .product-row:hover .cxai-product-img {
    transform: scale(1.25);
  }
}

@media screen and (max-width: 991px) {
  .cxai-product-img {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  #cxneuro {
    height: 40px;
  }
}
