footer {
  margin-top: 5rem;
  padding: 3rem 4rem;
  background-image: linear-gradient(to right, #152331, black);
}

.socials-container {
  text-align: right;
}

.socials-container h5 {
  display: inline-block;
  margin-right: 1rem;
}

.socials a .fab {
  padding-top: 1rem;
  padding-right: 1rem;
  color: white;
  font-size: 1.5rem;
}

.logo {
  height: 3.5rem;
  width: 7rem;
  align-items: left;
}

.socials {
  display: inline-block;
}

.footer-div {
  border-bottom: 0.5px solid #635c5c;
}

.footer-about-wrapper {
  display: flex;
  flex-direction: column;
}

.footer-about {
  flex-grow: 1;
}

#about-text {
  text-align: justify;
}

.footer-map {
  height: inherit;
  width: fit-content;
}

.footer-contact-header {
  display: flex;
}

.footer-contact-header h4,
.footer-contact-header p {
  display: inline-block;
  width: 50%;
}

.footer-contact-header p {
  text-align: right;
  font-size: small;
}

.footer-widget {
  margin: 2rem 0;
  padding: 0 3rem;
}

.footer-about-wrapper .footer-widget {
  padding-left: 0;
}

.footer-contact .footer-widget {
  padding-right: 0;
}

.footer-contact .footer-widget {
  border-left: 0.5px solid #635c5c;
}

.footer-widget p {
  line-height: 25px;
}

p {
  font-size: medium;
}

iframe {
  border-radius: 10px;
}

.footer-cp {
  display: flex;
  padding-right: 3rem;
}

.footer-cp .copyrights {
  flex-grow: 1;
  font-size: small;
  text-align: left;
  align-self: flex-end;
}

.terms {
  font-size: small;
}

.footer-logo a {
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  footer {
    padding: 3rem 2rem;
  }
  .socials-container {
    text-align: left;
  }
  .footer-widget {
    margin: 2rem 0;
    padding: 0 1rem;
  }
  .footer-contact .footer-widget {
    border: none;
  }
  .footer-contact .footer-widget {
    padding-left: 0;
  }
  .footer-cp {
    padding: 0;
  }
  .footer-contact-header h4,
  .footer-contact-header p {
    display: block;
    width: 100%;
  }
  .footer-contact-header p {
    text-align: left;
  }
}
