#card{
  border: none !important;
  background-color: transparent;
  position: relative;
}

.team{
  background-image: linear-gradient(to right,#152331, black);
}

#circle{
  border-bottom: 3px solid #D9D9D9;
  border-radius: 50%;
  height: 175px;
  width: 175px;
  padding: 1rem;
}

.quali , #designation {
  font-size: small;
  margin: 0 0 0;
}

#designation {
  margin: .5rem .5rem;
}

.circle-image {
  margin: auto;
  border-image-repeat: repeat;
  height: 100%;
  width: 100%;
  clip-path: circle(50%);
}

.designation{
  margin: 0 0 0;
}

.heading-title {
  width: fit-content;
  margin: 2rem auto;
  margin-bottom: 3rem;
  padding-bottom: 0.5rem;
  color: white;
  border-bottom: 0.5px solid #aaaaaa;
  font-family: "Catamaran";
}

.name{
  margin: 16px 0 0;
}

.social-media-icons i{
  color: white;
  margin: 16px 0 0;
}
