.navbar-main {
  position: fixed !important;
  background: rgba(0, 0, 0, 0);
  z-index: 3;
  width: 100%;
}

.scrolling {
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(4px);
  transition: 200ms ease;
}

.logo-img {
  height: 50px;
  margin-right: 10px;
  margin-left: -20px;
  margin-bottom: 3px;
}

.logo-text {
  align-items: inline;
}

.nav-item {
  font-size: 0.9rem;
  color: white !important;
  line-height: 2;
  border-radius: 13px;
}

/* to center align the support icon */
.nav-item:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-item:hover {
  background-color: rgba(102, 102, 102, 0.6);
  transition: 400ms ease-out;
  transform: translate(5px, -2px);
}

.navbar-logo {
  font-size: 2rem;
  font-weight: 600;
  align-items: inline;
  background: linear-gradient(to right, #f12711, #f5af19);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
